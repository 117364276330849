<template>
    <b-card
      v-if="data"
      class="card-transaction"
      no-body
    >
    <b-overlay
        :show="loading"
        rounded
        opacity="0.6"
        spinner-variant="primary"
    >
      <b-card-header>
        <div>
            <b-card-title>Sales by States</b-card-title>
            <b-card-text class="font-small-2">
                Monthly Sales Overview
            </b-card-text>
        </div>
      </b-card-header>
  
      <b-card-body class="scrolable-div">
        <div
          v-for="(s,index) in sales"
          :key="index"
          class="transaction-item "
        >
          <b-media no-body>
            <b-media-body>
              <h6 class="text-simple">
                {{ s.revenue }}
              </h6>
              <small>{{  getStateName(s.state) }}</small>
            </b-media-body>
          </b-media>
          <div v-if="s.change!=0"
            class="font-weight-bolder"
            :class="s.change<0 ? 'text-danger':'text-success'"
          >
          <feather-icon :icon="s.change<0? 'ChevronDownIcon':'ChevronUpIcon'"></feather-icon>
            {{ s.change.toFixed(2) }}%
          </div>
        </div>
      </b-card-body>
    </b-overlay>
    </b-card>
  </template>
  
  <script>
  import {
    BOverlay, BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
  } from 'bootstrap-vue'
  import {get_sales_by_states} from '@/apis/admin/dashboard'
  import moment from 'moment'
  import { State }  from 'country-state-city';
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BMediaBody,
      BMedia,
      BMediaAside,
      BAvatar,
      BDropdown,
      BDropdownItem,
      BOverlay,
    },
    // props: {
    //   data: {
    //     type: Array,
    //     default: () => [],
    //   },
    // },
    data() {
      return {
        data: [],
        sales:[
            // {
            //     state:'Georgia',
            //     profit:87000,
            //     deduction: false,
            //     percentage:'9.4%'
            // },
            // {
            //     state:'New Jersey',
            //     profit:34400,
            //     deduction: false,
            //     percentage:'25.4%'
            // },
            // {
            //     state:'Florida',
            //     profit:44000,
            //     deduction: false,
            //     percentage:'25.4%'
            // },
            // {
            //     state:'Rhode Island',
            //     profit:68000,
            //     deduction: true,
            //     percentage:'36.4%'
            // },
            // {
            //     state:'Illinois',
            //     profit:77000,
            //     deduction: false,
            //     percentage:'25.4%'
            // },
            // {
            //     state:'Massachusetts',
            //     profit:54000,
            //     deduction: true,
            //     percentage:'15.4%'
            // }
        ],
        states:[],
        loading:false,
      }
    },
    mounted() {
      this.getSalesByStates()
      this.setStates()
    },
    methods: {
      getSalesByStates(){
        let month= moment(new Date).format('M')
        let year= moment(new Date).format('YYYY')
        this.loading = true
        get_sales_by_states(year, month).then(resp=>{
          this.loading = false
          this.sales = resp
        }).catch(err=>{
          this.loading=false
        })
      },
      setStates(){
            const states = State.getStatesOfCountry('US')
            this.states = states.map(state=> ({
                value: state.isoCode,
                text: state.name
            }))
        },
        getStateName(state){
          let stateName=this.states.find(s=>s.value==state)
          return stateName?stateName.text : state
        }
    },
  }
  </script>
  <style scoped>
  .text-simple{
    font-size: 15px;
    font-weight: 600;
    line-height:21px;
    color: #6E6B7B;
  }
  @media (min-width:769px) {
    .scrolable-div{
      height: 400px;
      overflow-y: auto;
    }
  }

  .scrolable-div::-webkit-scrollbar {
    width: 8px;
    position: absolute;
  }
  
  /* Track */
  .scrolable-div::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .scrolable-div::-webkit-scrollbar-thumb {
    background: rgb(228, 227, 227); 
    border-radius: 10px;
    margin-right: 5px;
  }
  
  /* Handle on hover */
  .scrolable-div::-webkit-scrollbar-thumb:hover {
    background: #004bff; 
  }
</style>
  