<template>
    <div>
        <div class="row">
            <div class="col-lg-6 col-12">
                <sales-overview></sales-overview>
            </div>
            <div class="col-lg-6 col-12">
                <revenue-generated></revenue-generated>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-12">
                <earnings-report :data="data.avgSessions" />
            </div>
            <div class="col-lg-6 col-12">
                <support-tracker :data="data.supportTracker"/>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-lg-6 col-12">
                <sales-by-states/>
            </div>
            <div class="col-lg-6 col-12">
                <total-earnings/>
            </div>
            <!-- <div class="col-lg-4 col-md-6 col-12">
                <monthly-compaign-stats/>
            </div> -->
        </div>
    </div>
</template>
<script>
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import EarningsReport from "@/components/functional-components/admin/dashboard/EarningsReport.vue"
import SupportTracker from "@/components/functional-components/admin/dashboard/SupportTracker.vue"
import MonthlyCompaignStats from '@/components/functional-components/admin/dashboard/MonthlyCompaignStats.vue'
import SalesByStates from '@/components/functional-components/admin/dashboard/SalesByStates.vue'
import TotalEarnings from '@/components/functional-components/admin/dashboard/TotalEarnings.vue'
import SalesOverview from '@/components/functional-components/admin/dashboard/SalesOverview.vue'
import RevenueGenerated from '@/components/functional-components/admin/dashboard/RevenueGenerated.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
import { kFormatter } from '@core/utils/filter'
export default{
    components:{
        StatisticCardWithAreaChart,
        EarningsReport,
        SupportTracker,
        MonthlyCompaignStats,
        SalesByStates,
        TotalEarnings,
        SalesOverview,
        RevenueGenerated
    },
    data() {
        return {
        data: {},
        }
    },
    created() {
        // data
        this.$http.get('/analytics/data')
        .then(response => { this.data = response.data 
            console.log('rev:',response.data)
        })
    },
    methods: {
        kFormatter,
    },
}
</script>