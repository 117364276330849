<template>
    <b-card
      v-if="data"
      class="card-transaction"
      no-body
    >
      <b-card-header>
        <div>
            <b-card-title>Monthly Compaign Stats</b-card-title>
            <b-card-text class="font-small-2">
                8.52k Social Visitors
            </b-card-text>
          </div>
  
        <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="18"
              class="text-body cursor-pointer"
            />
          </template>
          <b-dropdown-item href="#">
            Last 28 Days
          </b-dropdown-item>
          <b-dropdown-item href="#">
            Last Month
          </b-dropdown-item>
          <b-dropdown-item href="#">
            Last Year
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>
  
      <b-card-body>
        <div
          v-for="(s,index) in stats"
          :key="index"
          class="transaction-item"
        >
          <b-media no-body>
            <b-media-aside>
              <b-avatar
                rounded
                size="42"
                :variant="`light-${s.color}`"
              >
                <feather-icon
                  size="18"
                  :color="s.color"
                  :icon="s.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="transaction-title">
                {{ s.label }}
              </h6>
            </b-media-body>
          </b-media>
          <div
            class="font-weight-bolder"
          >
            {{ s.total }}
          </div>
        </div>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  import {
    BCard, BCardHeader, BCardTitle,BCardText, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
  } from 'bootstrap-vue'
  import { getUserData } from '@/auth/utils'
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BMediaBody,
      BMedia,
      BMediaAside,
      BAvatar,
      BDropdown,
      BDropdownItem,
      BCardText
    },
    // props: {
    //   data: {
    //     type: Array,
    //     default: () => [],
    //   },
    // },
    data() {
      return {
        data: [],
        stats:[
            {
                label: "Emails",
                icon:"MailIcon",
                total:"12,346",
                color:"success"
            },
            {
                label: "Opened",
                icon:"LinkIcon",
                total:"8,734",
                color:"info"
            },
            {
                label: "Clicked",
                icon:"MousePointerIcon",
                total:"967",
                color:"warning"
            },
            {
                label: "Subscribed",
                icon:"UsersIcon",
                total:"345",
                color:"primary"
            },
            {
                label: "Complaints",
                icon:"AlertTriangleIcon",
                total:"10",
                color:"secondary"
            },
            {
                label: "Unsubscribed",
                icon:"SlashIcon",
                total:32,
                color:"danger"
            }
        ]
      }
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        this.$http.get('/ecommerce/data')
        .then(response => {
            this.data = response.data

            // ? Your API will return name of logged in user or you might just directly get name of logged in user
            // ? This is just for demo purpose
            const userData = getUserData()
            this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
        })
      },
    },
  }
  </script>
  