<template>
    <b-card
      class="card-transaction"
      no-body
    >
    <b-overlay
        :show="loading"
        rounded
        opacity="0.6"
        spinner-variant="primary"
    >
      <b-card-header>
        <div>
            <span class="text-small">Sales Overview</span><br>
            <span class="heading-text">{{subscriptionDetails.totalSale | currency}}</span>
        </div>
  
        <div class="text-success text-simple">{{percentChange}}</div>
      </b-card-header>
      <b-card-body>
        <div class="row">
            <div class="col-5">
              <div class="align-middle">
                <b-avatar
                  rounded
                  size="30"
                  :variant="`light-info`"
                >
                  <feather-icon
                    size="18"
                    color="info"
                    icon="ShoppingCartIcon"
                  />
                </b-avatar>
                <span class="text-simple">
                  Subscriptions
                </span>
              </div>
              <div class="sub-heading mt-1">
                {{getPercentage(subscriptionDetails.activePaidTierUsers,subscriptionDetails.activeUsers )}}%
              </div>
              <div class="text-simple m-0">{{ subscriptionDetails.activePaidTierUsers}}
              </div>
            </div>
            <div class="col-2">
                <div class="line mx-auto h-25"></div>
                <div class="d-flex justify-content-center">
                  <b-avatar
                    :variant="`light`"
                  ><span class="text-simple">VS</span></b-avatar>
                </div>
                <div class="line mx-auto h-25"></div>
            </div>
            <div class="col-5">
              <div class="align-middle text-right">
                <span class="text-simple">
                  Visits
                </span>
                <b-avatar
                  rounded
                  size="30"
                  :variant="`light-primary`"
                >
                  <feather-icon
                    size="18"
                    color="primary"
                    icon="LinkIcon"
                  />
                </b-avatar>
              </div>
              <div class="sub-heading mt-1 text-right">
                {{getPercentage(subscriptionDetails.activeFreeTierUsers,subscriptionDetails.activeUsers )}}% 
              </div>
              <div class="text-simple m-0 text-right">{{ subscriptionDetails.activeFreeTierUsers }}
              </div>
            </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
              <b-progress
              :value="getPercentage(subscriptionDetails.activePaidTierUsers,subscriptionDetails.activeUsers)"
              max="100"
              height="10px"
              variant="primary"
              />
          </div>
      </div>
      </b-card-body>
    </b-overlay>
    </b-card>
  </template>
  
  <script>
  import {
   BOverlay, BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,BProgress
  } from 'bootstrap-vue'
  import {get_subscription_report} from '@/apis/admin/dashboard'
  export default {
    components: {
      BCard,
      BCardHeader,
      BCardTitle,
      BCardBody,
      BMediaBody,
      BMedia,
      BMediaAside,
      BAvatar,
      BDropdown,
      BDropdownItem,
      BProgress,
      BOverlay
    },
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    data(){
      return {
        subscriptionDetails:{
          activeFreeTierUsers:0,
          activePaidTierUsers:0,
          activeUsers:0,
          percentChange:0,
          totalSale:0
        },
        loading: false,
      }
    },
    mounted() {
      this.getSubscriptionReports()
    },
    methods:{
      getSubscriptionReports(){
        this.loading=true
        get_subscription_report().then(resp=>{
          this.loading=false
          this.subscriptionDetails=resp.data
        }).catch(err=>{
          this.loading=false
        })
      },
      getPercentage(value, total){
        return (value/total*100).toFixed(1)
      }
    }
  }
</script>
<style scoped>

.text-small{
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #4B465C;
  opacity: .5;
  
}
.heading-text{
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  color: #4B465C;
}
.text-simple{
 font-size: 15px;
 font-weight: 400;
 line-height: 22px;
 color: #4B465C;
  margin:0px 6px;
}
.sub-heading{
  font-size: 18px;
 font-weight: 600;
 line-height: 24px;
 color: #4B465C;
}
.line{
  width:1px;
  background-color: #4B465C;
  opacity:.3;
}
</style>
  