<template>
    <b-card no-body>
      <b-overlay
        :show="loading"
        rounded
        opacity="0.6"
        spinner-variant="primary"
      >
      <b-card-body class="pb-0">
    
        <b-avatar
          class="mb-1"
          :variant="`light-success`"
          size="45"
        >
          <feather-icon
            size="21"
            icon="CreditCardIcon"
          />
        </b-avatar>
        <div class="truncate">
          <h2 class="mb-25 font-weight-bolder">
            {{revenueGenerated | currency}}
          </h2>
          <span>Revenue Generated</span>
        </div>
      </b-card-body>
  
      <vue-apex-charts
        type="area"
        height="100"
        width="100%"
        ref="myChart"
        :options="chartOptionsComputed"
        :series="revenueGained.series"
      />
    </b-overlay>
    </b-card>
  </template>
  
  <script>
  import { BCard, BCardBody, BAvatar, BOverlay } from 'bootstrap-vue'
  import VueApexCharts from 'vue-apexcharts'
  import { $themeColors } from '@themeConfig'
  import {get_revenue_report} from '@/apis/admin/dashboard'
  export default {
    components: {
      VueApexCharts,
      BCard,
      BCardBody,
      BAvatar,
      BOverlay
    },
    props: {
      icon: {
        type: String,
        required: true,
      },
      statistic: {
        type: [Number, String],
        required: true,
      },
      statisticTitle: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'primary',
      },
      chartData: {
        type: Array,
        default: () => [],
      },
      chartOptions: {
        type: Object,
        default: null,
      },
    },
    data(){
        return {
            areaChartOptions:{
                grid: {
                    show: false,
                    padding: {
                    left: 0,
                    right: 0,
                    },
                },
                chart: {
                    toolbar: {
                    show: false,
                    },
                    sparkline: {
                    enabled: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth',
                    width: 2.5,
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                    shadeIntensity: 0.9,
                    opacityFrom: 0.5,
                    opacityTo: 0.2,
                    stops: [0, 80, 100],
                    },
                },
         
                yaxis: [
                    {
                    y: 0,
                    offsetX: 0,
                    offsetY: 0,
                    padding: {
                        left: 0,
                        right: 0,
                    },
                    },
                ],
                tooltip: {
                    x: { show: true },
                },
                xaxis: {
                  categories: ['Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat', 'Sun',],
                  labels: {
                      style: {
                          colors: '#6E6B7B',
                          fontSize: '0.86rem',
                          fontFamily: 'Montserrat',
                      },
                      show:true,
                  },
                },
                theme: {
                    monochrome: {
                    enabled: true,
                    color: $themeColors.primary,
                    shadeTo: 'light',
                    shadeIntensity: 0.65,
                    },
                },
              },
            revenueGained: {
                series: [
                {
                    name: 'Revenue',
                    data: [],
                },
                ],
                analyticsData: {
                subscribers: 92600,
                },
            },
            revenueGenerated:0,
            timeframe:'weekly',
            loading:false
        }
    },
    computed: {
      chartOptionsComputed() {
        if (this.chartOptions === null) {
          const options = JSON.parse(JSON.stringify(this.areaChartOptions))
          options.theme.monochrome.color = $themeColors['primary']
          return options
        }
        return this.chartOptions
      },
    },
    mounted(){
      this.getRevenueReport()
    },
    methods:{
      getRevenueReport(){
        let reportData=[]
        this.loading=true
        get_revenue_report({timeframe:this.timeframe}).then(res => {
          this.loading=false
          res.data.map(r=>{
            reportData.push(r.revenue)
          })
          this.$refs.myChart.updateSeries([{data:reportData}])
          this.revenueGenerated=reportData.reduce((a, b) => a + b, 0)/1000
        }).catch(err=>{
          this.loading=false
        })
        
      }
    }
  }
  </script>
  