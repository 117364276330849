import http from "@/utils/http";

export async function get_revenue_report(data) {
    const req = (await http.get(`/dashboard-reports/revenue`,{params:data}));
    return req.data;   
}

export async function get_subscription_report(params) {
    const req = (await http.get(`/subscription/report`,{params:params}));
    return req.data;   
}

export async function get_earnings_report(type){
    const req = (await http.get(`/dashboard-reports/earning-reports`,{params: type}));
    return req.data;   
}

export async function get_support_report(params){
    const req = (await http.get(`/support-queries/report`,{params: params}));
    return req.data;   
}

export async function get_sales_by_states(year,month){
    const req = (await http.get(`/subscription/revenue-by-state/${year}/${month}`));
    return req.data; 
}

export async function get_reports(type, data){
    const req = (await http.get(`/dashboard-reports/generate-report-${type}-link`, {params:data}));
    return req.data;
}

export async function get_invoice_reports(data){
    const req = (await http.get(`/dashboard-reports/invoice-report`, {params:data}));
    return req.data; 
}